import React, { useEffect } from "react";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";

import { PageLoader } from "../components/Loading";
import analytics from "../components/analytics";

const UserSideProvider = dynamic(() => import("../Providers/userSide"));
const DadosEstruturados = dynamic(() =>
  import("../components/DadosEstruturados").then((mod) => mod.DadosEstruturados)
);
const Metas = dynamic(() =>
  import("../components/meta").then((mod) => mod.Metas)
);

import "../styles/fonts.css";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    analytics.page();
    // this will fire the Page Track function on every new router change.
  }, []);

  return (
    <UserSideProvider>
      <Metas />
      <DadosEstruturados />
      <PageLoader />
      <main>
        <link
          itemProp="thumbnailUrl"
          href="https://agenciacaravela.com.br/img/icone/marujos.svg"
        />
        <span
          itemProp="thumbnail"
          itemScope
          itemType="http://schema.org/ImageObject"
        >
          <link
            itemProp="url"
            href="https://agenciacaravela.com.br/img/icone/marujos.svg"
          />
        </span>
        <Component {...pageProps} />
      </main>
    </UserSideProvider>
  );
}

export default MyApp;
