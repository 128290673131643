import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Container } from "./styles";

export function PageLoader() {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: string) => {
      return url !== router.asPath && setLoading(true);
    };

    const handleComplete = (url: string) => {
      return url === router.asPath && setLoading(false);
    };

    if (router.isReady) {
      setLoading(false);
    }

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  return loading ? <Loading /> : <></>;
}

function Loading() {
  return (
    <Container>
      <div className="loader"></div>
    </Container>
  );
}
